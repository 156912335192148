import { render, staticRenderFns } from "./GeneralSettingsJavascript.vue?vue&type=template&id=1c530091&scoped=true&"
import script from "./generalSettingsJavascript.js?vue&type=script&lang=js&"
export * from "./generalSettingsJavascript.js?vue&type=script&lang=js&"
import style0 from "./general-settings-javascript.scss?vue&type=style&index=0&id=1c530091&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c530091",
  null
  
)

export default component.exports