import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AppDetailsStepResponseModel from '@/domains/applications/models/AppDetailsStepResponseModel';
import { AppDetailsInterface } from '@/domains/pd-admin/types/my-apps/create-appication/steps/app-details';

const ENDPOINT_URL = '/market';

export const setApplicationDetailsToApi = (
  applicationDetailsInfo: AppDetailsInterface,
  applicationID: number | string,
): Promise<AppDetailsStepResponseModel | ErrorModel> => {
  const appData = new FormData();
  appData.append('name', applicationDetailsInfo.name);
  appData.append('name_ar', applicationDetailsInfo.name_ar);
  appData.append('app_category_id', `${applicationDetailsInfo.app_category_id}`);
  appData.append('is_html_enabled', applicationDetailsInfo.is_html_enabled ? '1' : '0');
  appData.append('url', applicationDetailsInfo.appURL);
  appData.append('description', applicationDetailsInfo.description);
  appData.append('description_ar', applicationDetailsInfo.descriptionArabic);
  appData.append('short_description', applicationDetailsInfo.shortDesciption);
  appData.append('short_description_ar', applicationDetailsInfo.shortDesciptionArabic);
  appData.append('developer_name_en', applicationDetailsInfo.developerName);
  appData.append('developer_name_ar', applicationDetailsInfo.developerNameArabic);
  appData.append('video', applicationDetailsInfo.videoURL);
  if (applicationDetailsInfo.appScreenshotsAr.length) {
    applicationDetailsInfo.appScreenshotsAr.map((screenshot, index) => {
      appData.append(`screen_shots_ar[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_ar`, '');
  if (applicationDetailsInfo.appScreenshotsEn.length) {
    applicationDetailsInfo.appScreenshotsEn.map((screenshot, index) => {
      appData.append(`screen_shots_en[${index}]`, screenshot);
    });
  } else appData.append(`screen_shots_en`, '');
  if (applicationDetailsInfo.iconFile) appData.append('icon', applicationDetailsInfo.iconFile);

  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${applicationID}`, appData)
    .then((response) => new AppDetailsStepResponseModel(response))
    .catch((error) => error);
};
