export enum CreateAppInputPropertiesEnum {
  AppNameArabic = 'appNameArabic',
  AppNameEnglish = 'appNameEnglish',
  AppURL = 'appURL',
  MaintainerEmail = 'maintainerEmail',
}

export enum CreateAppSelectPropertiesEnum {
  AppCategory = 'appCategory',
  AppLanguage = 'appLanguage',
}

export enum ApplicationLanguagesEnum {
  Arabic = 'Arabic',
  English = 'English',
}

export enum ApplicationLanguagesValuesEnum {
  Arabic = 'ar',
  English = 'en',
}

export enum ApplicationTypesEnum {
  Normal = 'Normal Application',
  Javascript = 'Javascript Application',
}

export enum ApplicationTypesValuesEnum {
  Normal = 'normal',
  Javascript = 'javascript',
}
