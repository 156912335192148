import BaseModel from '../../../api/models/BaseModel';
import PartnerCategoryModel from './PartnerCategoryModel';

class CategoriesResponseModel extends BaseModel {
  public categories: PartnerCategoryModel[];

  constructor(arg: any) {
    super(arg);
    this.categories = arg.categories;
  }
}

export default CategoriesResponseModel;
