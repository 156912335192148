import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';

export interface JSAppStateInterface {
  appNameArabic: string;
  appNameEnglish: string;
  appURL: string;
  appCategory: Record<string, string | number>;
  appLanguage: Record<string, string>;
  maintainerEmail: string;
  appType: string | ApplicationTypeProperty;
  description: string;
  descriptionArabic: string;
  shortDesciption: string;
  shortDesciptionArabic: string;
  developerName: string;
  developerNameArabic: string;
  videoURL: string;
  appScreenshotsAr: (string | File)[];
  appScreenshotsEn: (string | File)[];
  iconFile: File | string | null;
  coverPhoto: File | string | null;
  isHTMLEnabled: boolean;
  // scripts
  customCode: string;
  updateCode: string;
  customLocation: Record<string, string>;
}

export enum ActiveTabEnum {
  AppDetails = 'app-details',
  AppManagement = 'app-management',
}

export enum AppLanguageEnum {
  Arabic = 'Arabic',
  English = 'English',
}

export enum AppLanguageValuesEnum {
  Arabic = 'ar',
  English = 'en',
}

export enum AppCustomeLocationEnum {
  Global = 'Global',
  PurchaseEvent = 'Purchase Event',
  ProductDetailsEvent = 'Product Details Event',
  AddToCartEvent = 'Add To Cart Event',
  RemoveFromCartEvent = 'Remove From Cart Event',
}

export enum AppCustomeLocationValuesEnum {
  Global = '',
  PurchaseEvent = 'purchase_event',
  ProductDetailsEvent = 'product_details_event',
  AddToCartEvent = 'add_to_cart_event',
  RemoveFromCartEvent = 'remove_from_cart_event',
}

export interface CategoryInterface {
  id: number;
  name_ar: string;
  name_en: string;
}

export enum JSAppInputPropertiesEnum {
  AppNameArabic = 'appNameArabic',
  AppNameEnglish = 'appNameEnglish',
  AppURL = 'appURL',
  MaintainerEmail = 'maintainerEmail',
  ShortDesciption = 'shortDesciption',
  ShortDesciptionArabic = 'shortDesciptionArabic',
  VideoURL = 'videoURL',
  DeveloperName = 'developerName',
  DeveloperNameArabic = 'developerNameArabic',
  // scripts
  CustomCode = 'customCode',
  UpdateCode = 'updateCode',
}

export enum JSAppSelectPropertiesEnum {
  AppCategory = 'appCategory',
  AppLanguage = 'appLanguage',
  CustomLocation = 'customLocation',
}

export interface HeadscriptInterface {
  head_scripts_code: string;
  id?: number;
  location: null | AppCustomeLocationValuesEnum;
}
