import { render, staticRenderFns } from "./ApplicationDetails.vue?vue&type=template&id=13c4b0d0&scoped=true&"
import script from "./applicationDetails.js?vue&type=script&lang=js&"
export * from "./applicationDetails.js?vue&type=script&lang=js&"
import style0 from "./ApplicationDetails.vue?vue&type=style&index=0&id=13c4b0d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c4b0d0",
  null
  
)

export default component.exports