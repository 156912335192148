import ErrorModel from '@/api/models/ErrorModel';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import {
  AppCustomeLocationEnum,
  AppCustomeLocationValuesEnum,
  HeadscriptInterface,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/js-app-details.type';
import {
  EmptySelectEnum,
  JavascriptAppGeneralSettingsInputPropertiesEnum,
  JavascriptAppGeneralSettingsSelectPropertiesEnum,
  JavascriptAppGeneralSettingsStateInterface,
} from '@/domains/pd-admin/types/my-apps/create-appication/steps/general-settings.type';
import { computed, onBeforeMount, reactive, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { APP_CUSTOM_LOCATION_SCRIPTS } from '../../administration/apps-management/app-details/js-app-details/details/dropdownInfo';

const useCreateAppGeneralSettinsJavascript = (appID: number) => {
  const createAppGeneralSettingsState = reactive<JavascriptAppGeneralSettingsStateInterface>({
    customCode: '',
    updateCode: '',
    customLocation: {
      label: EmptySelectEnum.Empty,
      value: EmptySelectEnum.Empty,
    },
  });

  const rules = {
    customCode: {},
    updateCode: {},
    customLocation: {
      label: {},
      value: {},
    },
  };

  const v$ = useVuelidate(rules, createAppGeneralSettingsState);

  const appCustomLocationScripts = reactive({
    options: APP_CUSTOM_LOCATION_SCRIPTS,
  });

  const headScripts = reactive<Record<string, HeadscriptInterface[]>>({
    list: [],
  });

  onBeforeMount(async () => {
    if (appID) {
      if (isNaN(appID)) return;
      const appDetailsRes = await getApplicationData(appID);
      if (appDetailsRes instanceof ErrorModel) {
        return;
      }

      createAppGeneralSettingsState.customCode = `${appDetailsRes.payload.app.unique_id}_params`;
      headScripts.list = [...appDetailsRes.payload.headScripts];

      createAppGeneralSettingsState.updateCode = appDetailsRes.payload.headScripts?.length
        ? appDetailsRes.payload.headScripts[0].head_scripts_code
        : '';
      if (
        !appDetailsRes.payload.headScripts ||
        (appDetailsRes.payload.headScripts.length && appDetailsRes.payload.headScripts[0].location === null)
      ) {
        createAppGeneralSettingsState.customLocation = {
          label: AppCustomeLocationEnum.Global,
          value: AppCustomeLocationValuesEnum.Global,
        };
      } else {
        createAppGeneralSettingsState.customLocation = {
          ...appCustomLocationScripts.options.find(
            (location) => location.value === appDetailsRes.payload.headScripts[0].location,
          ),
        } ?? { label: '', value: '' };
      }
    }
  });

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: JavascriptAppGeneralSettingsInputPropertiesEnum,
  ) => {
    v$.value[dataProperty].$touch();
    createAppGeneralSettingsState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: JavascriptAppGeneralSettingsSelectPropertiesEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    createAppGeneralSettingsState[property].label = selectedValue.label;
    createAppGeneralSettingsState[property].value = selectedValue.value;
  };

  watch(
    () => createAppGeneralSettingsState.customLocation,
    (newCustomLocation) => {
      const emptyScript: HeadscriptInterface = {
        head_scripts_code: '',
        id: -1,
        location: AppCustomeLocationValuesEnum.Global,
      };
      let selectedScript: HeadscriptInterface;
      if (newCustomLocation.value === AppCustomeLocationValuesEnum.Global) {
        selectedScript = headScripts.list.find((script) => script.location === null) ?? emptyScript;
      } else {
        selectedScript = headScripts.list.find((script) => script.location === newCustomLocation.value) ?? emptyScript;
      }
      createAppGeneralSettingsState.updateCode = selectedScript?.head_scripts_code ?? '';
    },
    { deep: true },
  );

  const isSubmitScriptsDisabled = computed(() => {
    return v$.value.customCode.$invalid || v$.value.updateCode.$invalid || v$.value.customLocation.$invalid;
  });

  return {
    createAppGeneralSettingsState,
    v$,
    JavascriptAppGeneralSettingsInputPropertiesEnum,
    JavascriptAppGeneralSettingsSelectPropertiesEnum,
    EmptySelectEnum,
    appCustomLocationScripts,
    isSubmitScriptsDisabled,
    headScripts,
    onSelectionDataChange,
    onInputDataChange,
  };
};

export default useCreateAppGeneralSettinsJavascript;
