import { reactive, computed, ref, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { helpers, required, maxLength } from '@vuelidate/validators';
import { isValidURL } from '@/helpers/regex/URLs';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import {
  AppDetailsStateInterface,
  CreateAppDetailInputs,
} from '@/domains/pd-admin/types/my-apps/create-appication/steps/app-details';
import { ARABIC_TEXT, ENGLISH_TEXT } from '@/helpers/regex/text';
import ApplicationModel from '@/api/models/ApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import { RoutingRouteEnum } from '@/router/routes.enum';

const { useActions } = createNamespacedHelpers('application');

const useCreateAppDetails = (appID: number) => {
  const MAX_SCREENSHOTS_NUMBER = 10;
  const MIN_SCREENSHOTS_NUMBER = 1;
  const iconFileNameFromGlobal = ref('');
  const isIconSpecsValid = ref(true);
  const isArScreenshotsInvalid = ref(false);
  const isEnScreenshotsInvalid = ref(false);
  const isInitialArabicScreenshotsLoading = ref(false);
  const isInitialEnglishScreenshotsLoading = ref(false);
  const mdAppLink = ref('');
  const { addStepCompleted } = useActions(['addStepCompleted']);

  const createAppDetailsState = reactive<AppDetailsStateInterface>({
    appURL: '',
    description: '',
    descriptionArabic: '',
    shortDesciption: '',
    shortDesciptionArabic: '',
    developerName: '',
    developerNameArabic: '',
    videoURL: '',
    appScreenshotsAr: [],
    appScreenshotsEn: [],
    iconFile: null,
    storeLine: '',
  });

  const applicationDetails = reactive<Record<string, ApplicationModel | null>>({ app: null });
  const originalArabicScreenshots = reactive<Record<string, File[]>>({ list: [] });
  const originalEnglishScreenshots = reactive<Record<string, File[]>>({ list: [] });

  const initialArabicScreenshots = reactive({
    list: [
      ...createAppDetailsState.appScreenshotsAr.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ],
  });

  const initialEnglishScreenshots = reactive({
    list: [
      ...createAppDetailsState.appScreenshotsEn.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ],
  });

  const rules = {
    appURL: {
      required,
      appURL: helpers.withMessage('Application URL is required', (value: string) => isValidURL(value)),
    },
    description: {
      required,
      description: helpers.withMessage('Description is required', required),
    },
    descriptionArabic: {
      required,
      descriptionArabic: helpers.withMessage('من فضلك, اوصف التطبيق', required),
    },
    shortDesciption: {
      required,
      shortDesciption: helpers.withMessage('Short description exceeds the allowed size', maxLength(100)),
    },
    shortDesciptionArabic: {
      required,
      shortDesciptionArabic: helpers.withMessage('الوصف المختصر يتخطى المساحة المسموحة', maxLength(100)),
    },
    developerName: {
      required,
      developerName: helpers.withMessage('Please developer name in English', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
    developerNameArabic: {
      required,
      developerNameArabic: helpers.withMessage('من فضلك, ادخل اسم المطور باللغة العربية', (value: string) => {
        return ARABIC_TEXT.test(value);
      }),
    },
    videoURL: {
      videoURL: helpers.withMessage('Video URL is not valid', (value: string) => isValidURL(value) || value === ''),
    },
    appScreenshotsAr: {},
    appScreenshotsEn: {},
    iconFile: {
      required,
    },
    storeLine: {},
  };

  const v$ = useVuelidate(rules, createAppDetailsState);

  onBeforeMount(async () => {
    if (appID) {
      if (isNaN(appID)) return;
      const appDetailsRes = await getApplicationData(appID);
      if (appDetailsRes instanceof ErrorModel || !appDetailsRes.payload) {
        return;
      }

      if (appDetailsRes.payload.app.steps > 2) {
        addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_WebhookManagement);
        addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_PlansManagement);
        addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_Publish);
      }

      applicationDetails.app = appDetailsRes.payload.app;
      createAppDetailsState.appURL = appDetailsRes.payload.app_url ?? '';
      createAppDetailsState.description = appDetailsRes.payload.app.description ?? '';
      createAppDetailsState.descriptionArabic = appDetailsRes.payload.app.description_ar ?? '';
      createAppDetailsState.shortDesciption = appDetailsRes.payload.app.short_description ?? '';
      createAppDetailsState.shortDesciptionArabic = appDetailsRes.payload.app.short_description_ar ?? '';
      createAppDetailsState.developerName = appDetailsRes.payload.app.developer_name_en ?? '';
      createAppDetailsState.developerNameArabic = appDetailsRes.payload.app.developer_name_ar ?? '';
      createAppDetailsState.videoURL = appDetailsRes.payload.app.video ?? '';

      createAppDetailsState.appScreenshotsAr = appDetailsRes.payload.app.screen_shots_ar?.length
        ? appDetailsRes.payload.app.screen_shots_ar.split(',')
        : [];
      createAppDetailsState.appScreenshotsEn = appDetailsRes.payload.app.screen_shots_en?.length
        ? appDetailsRes.payload.app.screen_shots_en.split(',')
        : [];

      createAppDetailsState.iconFile = appDetailsRes.payload.app.icon ?? null;

      iconFileNameFromGlobal.value =
        typeof appDetailsRes.payload.app.icon === 'string'
          ? appDetailsRes.payload.app.icon
          : URL.createObjectURL(appDetailsRes.payload.app.icon);

      createAppDetailsState.storeLine = appDetailsRes.payload.development ?? '';
      mdAppLink.value = appDetailsRes.payload.app_url ?? '';
    }

    isInitialArabicScreenshotsLoading.value = true;
    originalArabicScreenshots.list = await getArabicScreenshotsFiles();

    initialArabicScreenshots.list = [
      ...createAppDetailsState.appScreenshotsAr.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ];
    isInitialArabicScreenshotsLoading.value = false;

    isInitialEnglishScreenshotsLoading.value = true;
    originalEnglishScreenshots.list = await getEnglishScreenshotsFiles();

    initialEnglishScreenshots.list = [
      ...createAppDetailsState.appScreenshotsEn.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ];

    isInitialEnglishScreenshotsLoading.value = false;
  });

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: CreateAppDetailInputs) => {
    v$.value[dataProperty].$touch();
    createAppDetailsState[dataProperty] = changedValue.value;
  };

  const onIconSelection = (fileInputEvent: Event) => {
    const input = fileInputEvent.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const appIcon = new Image();
    appIcon.src = URL.createObjectURL(input.files[0]);

    appIcon.onerror = () => {
      isIconSpecsValid.value = false;
      return;
    };

    appIcon.onload = () => {
      if (appIcon.width !== appIcon.height || appIcon.width > 250 || appIcon.height > 250) {
        isIconSpecsValid.value = false;
        return;
      }
      if (input.files) {
        createAppDetailsState.iconFile = input.files[0];
        iconFileNameFromGlobal.value = URL.createObjectURL(input.files[0]);
        isIconSpecsValid.value = true;
      }
    };
  };

  const areArabicFiles = computed(() => originalArabicScreenshots.list.length);

  const isSaveDisabled = computed(() => {
    const isIconValid = () =>
      createAppDetailsState.iconFile instanceof File || typeof createAppDetailsState.iconFile === 'string';
    return v$.value.$invalid || !isIconValid() || isArScreenshotsInvalid.value || isEnScreenshotsInvalid.value;
  });

  const showMessageForDescriptionArEnField = computed(() => {
    return (
      ((v$.value.descriptionArabic.$dirty || v$.value.description.$dirty) &&
        (v$.value.descriptionArabic.$invalid || v$.value.description.$invalid)) ||
      ((v$.value.shortDesciptionArabic.$dirty || v$.value.shortDesciption.$dirty) &&
        (v$.value.shortDesciptionArabic.$invalid || v$.value.shortDesciption.$invalid))
    );
  });

  const showMessageDeveloperNameField = computed(() => {
    return (
      (v$.value.developerName.$dirty || v$.value.developerNameArabic.$dirty) &&
      (v$.value.developerName.$invalid || v$.value.developerNameArabic.$invalid)
    );
  });

  const onArabicScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalArabicScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalArabicScreenshots.list = [...originalArabicScreenshots.list, ...newImages];

    const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || images.length < MIN_SCREENSHOTS_NUMBER || !areFiles) {
      isArScreenshotsInvalid.value = true;
    } else {
      isArScreenshotsInvalid.value = false;
    }
  };

  const onArabicScreenshotsRemove = (image: File) => {
    const screenIndex = originalArabicScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalArabicScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalArabicScreenshots.list.every((file) => file.type.includes('image/'));

      if (
        originalArabicScreenshots.list.length > MAX_SCREENSHOTS_NUMBER ||
        originalArabicScreenshots.list.length < MIN_SCREENSHOTS_NUMBER ||
        !areFiles
      ) {
        isArScreenshotsInvalid.value = true;
      } else {
        isArScreenshotsInvalid.value = false;
      }
    }
  };

  const onEnglishScreenshotsChange = (images: File[]) => {
    const newImages = images.filter(
      (screen) =>
        originalEnglishScreenshots.list.findIndex((originalScreen) => originalScreen.name === screen.name) == -1,
    );

    originalEnglishScreenshots.list = [...originalEnglishScreenshots.list, ...newImages];
    const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

    if (images.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
      isEnScreenshotsInvalid.value = true;
    } else {
      isEnScreenshotsInvalid.value = false;
    }
    return [];
  };

  const onEnglishScreenshotsRemove = (image: File) => {
    const screenIndex = originalEnglishScreenshots.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalEnglishScreenshots.list.splice(screenIndex, 1);
      const areFiles = originalEnglishScreenshots.list.every((file) => file.type.includes('image/'));

      if (originalEnglishScreenshots.list.length > MAX_SCREENSHOTS_NUMBER || !areFiles) {
        isEnScreenshotsInvalid.value = true;
      } else {
        isEnScreenshotsInvalid.value = false;
      }
    }
  };

  const getArabicScreenshotsFiles = async () => {
    return Promise.all(
      createAppDetailsState.appScreenshotsAr.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialArabicScreenshots.list = [];
        return [];
      });
  };

  const getEnglishScreenshotsFiles = async () => {
    return Promise.all(
      createAppDetailsState.appScreenshotsEn.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialEnglishScreenshots.list = [];
        return [];
      });
  };

  return {
    createAppDetailsState,
    v$,
    applicationDetails,
    isSaveDisabled,
    iconFileNameFromGlobal,
    isIconSpecsValid,
    showMessageForDescriptionArEnField,
    showMessageDeveloperNameField,
    isArScreenshotsInvalid,
    isEnScreenshotsInvalid,
    initialArabicScreenshots,
    initialEnglishScreenshots,
    isInitialArabicScreenshotsLoading,
    isInitialEnglishScreenshotsLoading,
    originalArabicScreenshots,
    originalEnglishScreenshots,
    areArabicFiles,
    mdAppLink,
    onIconSelection,
    onInputDataChange,
    onArabicScreenshotsChange,
    onEnglishScreenshotsChange,
    onArabicScreenshotsRemove,
    onEnglishScreenshotsRemove,
  };
};

export default useCreateAppDetails;
