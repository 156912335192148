import Vue from 'vue';
import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, maxLength, required, requiredIf } from '@vuelidate/validators';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import PlanModel from '@/domains/applications/models/PlanModel';
import { ARABIC_TEXT, ARABIC_TEXT_WITH_NEW_LINE, ENGLISH_TEXT, ENGLISH_TEXT_WITH_NEW_LINE } from '@/helpers/regex/text';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';
import {
  AppPlanStateInterface,
  PlanInfoType,
  PlanInputPropertiesEnum,
  PlanSelectionPropertiesEnum,
  PlansInvoicePeriodEnum,
  PlanTypes,
  PlanOfferType,
  PlanOfferTypeLabelsEnum,
  PlanOfferTypeValuesEnum,
} from '@/domains/pd-admin/types/my-apps/create-appication/steps/app-plans';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { deletePlan, sendPlanToAPI } from '@/domains/pd-admin/api/my-apps/steps/plans-management/plansManagement';

const { useActions } = createNamespacedHelpers('application');

const useAppPlans = (appID: number) => {
  const showShowAddPlanModal = ref(false);
  const isLoadingPlansList = ref(false);
  const isLoadingSaveOrEdit = ref(false);
  const isLoadingDeletePlan = ref(false);
  const planType = ref('');
  const isOfferApplied = ref(false);
  const isAddPlanDisabled = ref(false);
  const showDeletePlanModal = ref(false);
  const currentlySelectedPurchasableID = ref('');
  const currentlySelectedPlanID = ref(0);
  const currentlySelectedPlanOfferID = ref(0);
  const currentlySelectedPlanOfferPurchasableID = ref('');
  const selectedPlanOfferType = ref(PlanOfferTypeValuesEnum.BuyXGetYOffer);
  const MIN_TRIAL_DAYS = 0;
  const MAX_TRIAL_DAYS = 90;

  const plansList: Record<string, PlanModel[]> = reactive({ plansList: [] });

  const toggleDeletePlanModal = () => (showDeletePlanModal.value = !showDeletePlanModal.value);
  const toggleShowAddPlanModal = () => (showShowAddPlanModal.value = !showShowAddPlanModal.value);
  const togglePlanOffer = () => {
    isOfferApplied.value = !isOfferApplied.value;
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    appPlanState.planOfferDuration = { label: '', value: '' };
    appPlanState.planDiscountOffer = '';
  };

  const { addStepCompleted } = useActions(['addStepCompleted']);

  const appPlanState = reactive<AppPlanStateInterface>({
    id: -1,
    planName: '',
    planNameArabic: '',
    planDuration: {
      label: '',
      value: '',
    },
    planOfferDuration: {
      label: '',
      value: '',
    },
    planDescription: '',
    planDescriptionArabic: '',
    pricePlan: '',
    trialDays: {
      label: '',
      value: '',
    },
    planFeatures: '',
    planFeaturesArabic: '',
    planDiscountOffer: '',
  });

  const trialDaysList = [];

  for (let i = MIN_TRIAL_DAYS; i <= MAX_TRIAL_DAYS; i++) {
    trialDaysList.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const validatePlanFeaturesEntry = () => {
    const lines = appPlanState.planFeatures.split('\n');
    if (
      lines.length > 5 ||
      lines.some((line) => line.length > 50) ||
      lines.some((line) => !ENGLISH_TEXT.test(line)) ||
      appPlanState.planFeatures === ''
    )
      return false;
    else return true;
  };

  const validateArabicPlanFeaturesEntry = () => {
    const lines = appPlanState.planFeaturesArabic.split('\n');
    if (
      lines.length > 5 ||
      lines.some((line) => line.length > 50) ||
      lines.some((line) => !ARABIC_TEXT.test(line)) ||
      appPlanState.planFeaturesArabic === ''
    )
      return false;
    else return true;
  };

  const rules = {
    planName: {
      required,
      planName: helpers.withMessage('Plan name is required in both English and Arabic', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
    planNameArabic: {
      required,
      planNameArabic: helpers.withMessage('Plan name is required in both Arabic and English', (value: string) => {
        return ARABIC_TEXT.test(value);
      }),
    },
    planDuration: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    planOfferDuration: {
      label: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
      value: {
        required: helpers.withMessage(
          'Select a valid duration for offer',
          requiredIf(
            () => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer,
          ),
        ),
      },
    },
    planDescription: {
      required,
      planDescription: helpers.withMessage(
        'Minimum lenght: 50 charachters, Maximum length: 300 characters',
        (value: string) => {
          return ENGLISH_TEXT_WITH_NEW_LINE.test(value);
        },
      ),
      maxLength: maxLength(300),
      minLength: minLength(50),
    },
    planDescriptionArabic: {
      required,
      planDescriptionArabic: helpers.withMessage(
        'Minimum lenght: 50 charachters, Maximum length: 300 characters',
        (value: string) => {
          return ARABIC_TEXT_WITH_NEW_LINE.test(value);
        },
      ),
      maxLength: maxLength(300),
      minLength: minLength(50),
    },
    pricePlan: {
      pricePlan: helpers.withMessage('Minimum price: 1 SAR, Maximum price: 120000 SAR', () => {
        return parseFloat(appPlanState.pricePlan) >= 1 && parseFloat(appPlanState.pricePlan) <= 120000;
      }),
    },
    trialDays: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    planFeatures: {
      planFeatures: helpers.withMessage(
        'Only maximum of 5 lines allowed with 50 characters in each line.',
        validatePlanFeaturesEntry,
      ),
    },
    planFeaturesArabic: {
      planFeaturesArabic: helpers.withMessage(
        'Only maximum of 5 lines allowed with 50 characters in each line.',
        validateArabicPlanFeaturesEntry,
      ),
    },
    planDiscountOffer: {
      required: helpers.withMessage(
        'Provide a valid discount offer for plan',
        requiredIf(() => isOfferApplied.value && selectedPlanOfferType.value === PlanOfferTypeValuesEnum.DiscountOffer),
      ),
      planDiscountOffer: helpers.withMessage('Minimum discount: 1%, Maximum discount: 60%', (value: string) => {
        return (
          (parseFloat(appPlanState.planDiscountOffer) >= 1 && parseFloat(appPlanState.planDiscountOffer) <= 60) ||
          value === ''
        );
      }),
    },
  };

  const v$ = useVuelidate(rules, appPlanState);

  const durationsList = [
    { label: 'Free', value: PlanTypes.Free },
    { label: '1 Month', value: '30' },
    { label: '2 Months', value: '60' },
    { label: '3 Months', value: '90' },
    { label: '4 Months', value: '120' },
    { label: '5 Months', value: '150' },
    { label: '6 Months', value: '180' },
    { label: '7 Months', value: '210' },
    { label: '8 Months', value: '240' },
    { label: '9 Months', value: '270' },
    { label: '10 Months', value: '300' },
    { label: '11 Months', value: '330' },
    { label: 'Yearly', value: '365' },
  ];

  const offerableDurationsList = computed(() => {
    return durationsList
      .filter((duration) => Number(duration.value) <= Number(appPlanState.planDuration.value))
      .slice(1);
  });

  const isAddOrEditPlanModalButtonDisabled = computed((): boolean => {
    if (appPlanState.planDuration.value === PlanTypes.Free) {
      return (
        v$.value.planName.$invalid ||
        v$.value.planNameArabic.$invalid ||
        v$.value.planDuration.$invalid ||
        v$.value.planDescription.$invalid ||
        v$.value.planDescriptionArabic.$invalid ||
        v$.value.planFeatures.$invalid ||
        v$.value.planFeaturesArabic.$invalid
      );
    } else return v$.value.$invalid;
  });

  const resetData = () => {
    currentlySelectedPlanID.value = 0;
    currentlySelectedPurchasableID.value = '';
    currentlySelectedPlanOfferID.value = 0;
    currentlySelectedPlanOfferPurchasableID.value = '';
    isOfferApplied.value = false;
    appPlanState.planName = '';
    appPlanState.planNameArabic = '';
    appPlanState.planDuration = {
      label: '',
      value: '',
    };
    appPlanState.planDescription = '';
    appPlanState.planDescriptionArabic = '';
    appPlanState.pricePlan = '';
    appPlanState.trialDays = {
      label: '',
      value: '',
    };
    appPlanState.planFeatures = '';
    appPlanState.planFeaturesArabic = '';
  };

  const onPlanOfferRadioClick = (plan: Record<string, PlanOfferTypeValuesEnum>) =>
    (selectedPlanOfferType.value = plan.value);

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: PlanInputPropertiesEnum) => {
    v$.value[dataProperty].$touch();
    appPlanState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: PlanSelectionPropertiesEnum) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    appPlanState[property].label = selectedValue.label;
    appPlanState[property].value = selectedValue.value;
    if (property === PlanSelectionPropertiesEnum.PlanDuration && selectedValue.value === PlanTypes.Free) {
      appPlanState.pricePlan = '0';
      appPlanState.trialDays = { label: '0', value: '0' };
    }
  };

  onBeforeMount(() => {
    fetchAppPlans();
  });

  const fetchAppPlans = async () => {
    isLoadingPlansList.value = true;
    if (isNaN(appID)) {
      isLoadingPlansList.value = false;
      return;
    }
    const appDetailsRes = await getApplicationData(appID);
    if (appDetailsRes instanceof ErrorModel || !appDetailsRes.payload) {
      isLoadingPlansList.value = false;
      return;
    }
    plansList.plansList = [...appDetailsRes.payload.plans];
    isAddPlanDisabled.value = plansList.plansList.length >= 4;
    isLoadingPlansList.value = false;
  };

  const onPlanRadioClick = (plan: Record<string, string>) => (planType.value = plan.value);

  const showDetailsSection = computed(() => {
    return (planType.value === PlanTypes.Paid && plansList.plansList.length === 0) || planType.value === PlanTypes.Free;
  });

  const onContinueClicked = () => {
    resetData();
    v$.value.$reset();
    router.push({ name: RoutingRouteEnum.Admin_CreateApplication_Publish });
  };

  const onSaveFreePlan = async () => {
    if (plansList.plansList.length && appID) {
      let isDeletionFailure = false;
      plansList.plansList.forEach(async (plan) => {
        const response = await deletePlan(plan.purchasable_id, appID);
        if (response instanceof ErrorModel || !response.deleted) {
          isDeletionFailure = true;
          return;
        }
      });
      if (isDeletionFailure) {
        Vue.$toast.error('Could not save free plan');
        return;
      }
    }
    addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_Publish);
    Vue.$toast.success('Free plan has been saved');
    router.push({ name: RoutingRouteEnum.Admin_CreateApplication_Publish });
  };

  const onPlanDelete = (planPurchasableID: string) => {
    currentlySelectedPurchasableID.value = planPurchasableID;
    toggleDeletePlanModal();
  };

  const onDeleteConfirmationClicked = async () => {
    if (appID) {
      isLoadingDeletePlan.value = true;
      const response = await deletePlan(currentlySelectedPurchasableID.value, appID);
      if (response instanceof ErrorModel || !response.deleted) {
        isLoadingDeletePlan.value = false;
        currentlySelectedPurchasableID.value = '';
        Vue.$toast.error('Failed to delete plan');
        resetData();
        v$.value.$reset();
        toggleDeletePlanModal();
        return;
      }
      await fetchAppPlans();
      isLoadingDeletePlan.value = false;
      currentlySelectedPurchasableID.value = '';
      Vue.$toast.success('Plan has been deleted');
      resetData();
      v$.value.$reset();
      toggleDeletePlanModal();
    }
  };

  const onPlanEdit = (planPurchasableID: string, planID: number) => {
    if (!showShowAddPlanModal.value) toggleShowAddPlanModal();
    if (currentlySelectedPurchasableID.value !== planPurchasableID || currentlySelectedPlanID.value !== planID) {
      resetData();
      v$.value.$reset();
    }

    currentlySelectedPlanID.value = planID;
    currentlySelectedPurchasableID.value = planPurchasableID;

    const selectedPlan = plansList.plansList.find((plan) => plan.id === planID);
    currentlySelectedPlanOfferID.value = selectedPlan?.offer?.id ?? 0;
    currentlySelectedPlanOfferPurchasableID.value = selectedPlan?.offer?.offer_purchasable_id ?? '';

    const selectedPlanDuration = () => {
      if (selectedPlan?.price === 0) return { label: 'Free', value: PlanTypes.Free };
      else if (selectedPlan && selectedPlan.invoice_period % PlansInvoicePeriodEnum.Monthly == 0)
        return {
          label: new String(selectedPlan.invoice_period / PlansInvoicePeriodEnum.Monthly).concat(' Months'),
          value: selectedPlan.invoice_period.toString(),
        };
      else if (selectedPlan && selectedPlan.invoice_period === PlansInvoicePeriodEnum.Yearly)
        return { label: 'Yearly', value: selectedPlan.invoice_period.toString() };
    };
    appPlanState.planName = selectedPlan?.name_en || '';
    appPlanState.planNameArabic = selectedPlan?.name_ar || '';
    appPlanState.planDuration = selectedPlanDuration() || { label: '', value: '' };
    appPlanState.planDescription = selectedPlan?.description_en || '';
    appPlanState.planDescriptionArabic = selectedPlan?.description_ar || '';
    appPlanState.pricePlan = `${selectedPlan?.price}` || '';
    isOfferApplied.value = !!selectedPlan?.offer?.offer_period;
    selectedPlanOfferType.value = selectedPlan?.plan_discount
      ? PlanOfferTypeValuesEnum.DiscountOffer
      : PlanOfferTypeValuesEnum.BuyXGetYOffer;
    appPlanState.planDiscountOffer = selectedPlan?.plan_discount || '';
    appPlanState.planOfferDuration = {
      label: durationsList.find((duration) => duration.value == selectedPlan?.offer?.offer_period)?.label ?? '',
      value: selectedPlan?.offer?.offer_period ?? '',
    };
    appPlanState.trialDays = {
      label: `${selectedPlan?.trial_period}`,
      value: `${selectedPlan?.trial_period}`,
    };
    appPlanState.planFeatures = selectedPlan?.features_en || '';
    appPlanState.planFeaturesArabic = selectedPlan?.features_ar || '';
    v$.value.$validate();
  };

  const onAddOrEditPlanClicked = async () => {
    isLoadingSaveOrEdit.value = true;
    const oneLineFeaturesContainer = appPlanState.planFeatures.split('\n').join(' \n ');
    const oneLineArabicFeaturesContainer = appPlanState.planFeaturesArabic.split('\n').join(' \n ');
    const planInfo: Record<string, PlanInfoType> = {
      pricingPlans: {
        id: currentlySelectedPlanID.value !== 0 ? currentlySelectedPlanID.value : null,
        purchasable_id: currentlySelectedPurchasableID.value !== '' ? currentlySelectedPurchasableID.value : null,
        name_en: appPlanState.planName,
        name_ar: appPlanState.planNameArabic,
        description: appPlanState.planDescriptionArabic,
        description_en: appPlanState.planDescription,
        features: oneLineArabicFeaturesContainer,
        features_en: oneLineFeaturesContainer,
        price: `${appPlanState.pricePlan}`,
        duration: appPlanState.planDuration.value,
        currency: 'SAR',
        trial_period: Number(appPlanState.trialDays.value),
        offer: null,
      },
    };
    const offerData: PlanOfferType = {
      id: currentlySelectedPlanOfferID.value !== 0 ? currentlySelectedPlanOfferID.value : null,
      offer_purchasable_id:
        currentlySelectedPlanOfferPurchasableID.value !== '' ? currentlySelectedPlanOfferPurchasableID.value : null,
    };

    if (isOfferApplied.value) {
      if (selectedPlanOfferType.value === PlanOfferTypeValuesEnum.BuyXGetYOffer) {
        offerData.offer_period = appPlanState.planOfferDuration.value;
        offerData.offer_type = PlanOfferTypeLabelsEnum.BuyXGetY;
      } else {
        offerData.offer_period = '30';
        offerData.offer_discount = +appPlanState.planDiscountOffer;
        offerData.offer_type = PlanOfferTypeLabelsEnum.Discount;
      }
      planInfo.pricingPlans.offer = offerData;
    } else {
      offerData.offer_period = null;
      planInfo.pricingPlans.offer = offerData;
    }
    const response = await sendPlanToAPI(planInfo, appID);

    if (response instanceof ErrorModel || !response.plan) {
      resetData();
      v$.value.$reset();
      isLoadingSaveOrEdit.value = false;
      Vue.$toast.error('Failed to add plan');
      return;
    }

    await fetchAppPlans();
    resetData();
    v$.value.$reset();
    isLoadingSaveOrEdit.value = false;
    if (currentlySelectedPurchasableID.value !== '') Vue.$toast.success('Plan has been edited successfully');
    else Vue.$toast.success('Added new plan successfully');
    toggleShowAddPlanModal();
  };

  const onAddPlanClicked = () => {
    toggleShowAddPlanModal();
  };

  const onCloseAddOrEditPlanModal = () => {
    resetData();
    v$.value.$reset();
    toggleShowAddPlanModal();
  };

  const onBackClicked = () => {
    router.push({ name: RoutingRouteEnum.Admin_CreateApplication_WebhookManagement });
  };

  const showMessagePlanEnArField = computed(() => {
    return (
      (v$.value.planName.$dirty || v$.value.planNameArabic.$dirty) &&
      (v$.value.planName.$invalid || v$.value.planNameArabic.$invalid)
    );
  });

  const showMessagePlanDescriptionEnArField = computed(() => {
    return (
      (v$.value.planDescription.$dirty || v$.value.planDescriptionArabic.$dirty) &&
      (v$.value.planDescription.$invalid || v$.value.planDescriptionArabic.$invalid)
    );
  });

  const showMessagePlanFeaturesEnArField = computed(() => {
    return (
      (v$.value.planFeatures.$dirty || v$.value.planFeaturesArabic.$dirty) &&
      (v$.value.planFeatures.$invalid || v$.value.planFeaturesArabic.$invalid)
    );
  });

  return {
    PlanTypes,
    appPlanState,
    durationsList,
    trialDaysList,
    isOfferApplied,
    PlanInputPropertiesEnum,
    PlanSelectionPropertiesEnum,
    v$,
    planType,
    isAddPlanDisabled,
    showDeletePlanModal,
    plansList,
    isLoadingPlansList,
    offerableDurationsList,
    isAddOrEditPlanModalButtonDisabled,
    showShowAddPlanModal,
    isLoadingSaveOrEdit,
    isLoadingDeletePlan,
    currentlySelectedPurchasableID,
    showMessagePlanEnArField,
    showMessagePlanDescriptionEnArField,
    PlansInvoicePeriodEnum,
    showMessagePlanFeaturesEnArField,
    showDetailsSection,
    selectedPlanOfferType,
    PlanOfferTypeValuesEnum,
    togglePlanOffer,
    onSelectionDataChange,
    onInputDataChange,
    onPlanRadioClick,
    onSaveFreePlan,
    onPlanDelete,
    toggleDeletePlanModal,
    onDeleteConfirmationClicked,
    onPlanEdit,
    toggleShowAddPlanModal,
    onContinueClicked,
    onPlanOfferRadioClick,
    onAddOrEditPlanClicked,
    validatePlanFeaturesEntry,
    onCloseAddOrEditPlanModal,
    onAddPlanClicked,
    onBackClicked,
  };
};

export default useAppPlans;
