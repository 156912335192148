import {
  AppCustomeLocationEnum,
  AppCustomeLocationValuesEnum,
} from '../../../administration/apps-management/app-details/js-app-details.type';

export interface JavascriptAppGeneralSettingsStateInterface {
  customCode: string;
  updateCode: string;
  customLocation: Record<string, AppCustomeLocationEnum | AppCustomeLocationValuesEnum | 'empty'>;
}

export enum EmptySelectEnum {
  Empty = 'empty',
}

export enum JavascriptAppGeneralSettingsInputPropertiesEnum {
  CustomCode = 'customCode',
  UpdateCode = 'updateCode',
}

export enum JavascriptAppGeneralSettingsSelectPropertiesEnum {
  CustomLocation = 'customLocation',
}
