class CreateApplicationInformationModel {
  public name: string;
  public name_ar: string;
  public url: string;
  public app_category_id: number;
  public language: 'en' | 'ar';
  public type: '0' | '1';
  public email: string;

  constructor(arg: any) {
    this.name = arg.name;
    this.name_ar = arg.name_ar;
    this.url = arg.url;
    this.app_category_id = arg.app_category_id;
    this.language = arg.language;
    this.type = arg.type;
    this.email = arg.email;
  }
}

export default CreateApplicationInformationModel;
