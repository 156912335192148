export interface AppPlanStateInterface {
  id: number;
  planName: string;
  planNameArabic: string;
  planDuration: {
    label: string;
    value: string;
  };
  planOfferDuration: {
    label: string;
    value: string;
  };
  planDescription: string;
  planDescriptionArabic: string;
  pricePlan: string;
  trialDays: {
    label: string;
    value: string;
  };
  planFeatures: string;
  planFeaturesArabic: string;
  planDiscountOffer: string;
}

export enum PlanTypes {
  Free = '0',
  Paid = 'paid',
}

export type PlanType = {
  id: number;
  planName: string;
  planNameArabic: string;
  planDuration: {
    label: string;
    value: string;
  };
  planDescription: string;
  pricePlan: string;
  trialDays: {
    label: string;
    value: string;
  };
  planFeatures: string;
};

export enum PlanInputPropertiesEnum {
  PlanName = 'planName',
  PlanNameArabic = 'planNameArabic',
  PlanDescription = 'planDescription',
  PlanDescriptionArabic = 'planDescriptionArabic',
  PricePlan = 'pricePlan',
  PlanFeatures = 'planFeatures',
  PlanFeaturesArabic = 'planFeaturesArabic',
  PlanDiscountOffer = 'planDiscountOffer',
}

export enum PlanSelectionPropertiesEnum {
  PlanDuration = 'planDuration',
  PlanOfferDuration = 'planOfferDuration',
  TrialDays = 'trialDays',
}

export enum PlansInvoicePeriodEnum {
  Monthly = 30,
  Yearly = 365,
}

export enum PlanOfferTypeLabelsEnum {
  BuyXGetY = 'buy_x_get_y',
  Discount = 'discount',
}

export enum PlanOfferTypeValuesEnum {
  BuyXGetYOffer = '1',
  DiscountOffer = '2',
}

export type PlanOfferType = {
  id: number | null;
  offer_purchasable_id: string | null;
  offer_type?: PlanOfferTypeLabelsEnum;
  offer_period?: string | null;
  offer_discount?: number;
};

export type PlanInfoType = {
  id: number | null;
  purchasable_id: string | null;
  name_en: string;
  name_ar: string;
  description: string;
  description_en: string;
  features: string;
  features_en: string;
  price: string;
  duration: string;
  currency: string;
  trial_period: number;
  offer: PlanOfferType | null;
};
