<template>
  <div class="publish-app">
    <div class="px-4 py-6 my-8 mx-6 bg-white-pureWhite shadow-md">
      <zid-breadcrumb>
        <zid-breadcrumb-item :route="RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript">
          <base-text textStyle="h4"> General Settings </base-text>
        </zid-breadcrumb-item>
        <zid-breadcrumb-item :route="RoutingRouteEnum.Admin_CreateApplication_AppDetails">
          <base-text textStyle="h4"> Application Details </base-text>
        </zid-breadcrumb-item>
        <zid-breadcrumb-item :route="RoutingRouteEnum.Admin_CreateApplication_WebhookManagement">
          <base-text textStyle="h4"> Webhook Management </base-text>
        </zid-breadcrumb-item>
        <zid-breadcrumb-item :route="RoutingRouteEnum.Admin_CreateApplication_PlansManagement">
          <base-text textStyle="h4"> Plans Management </base-text>
        </zid-breadcrumb-item>
        <zid-breadcrumb-item :route="RoutingRouteEnum.Admin_CreateApplication_Publish">
          <base-text textStyle="h4"> Request to Publish </base-text>
        </zid-breadcrumb-item>
      </zid-breadcrumb>
    </div>
    <div class="px-10">
      <transition name="slide" mode="out-in">
        <div v-if="isAppInstalled" class="p-8 mb-8 bg-white-pureWhite rounded-lg shadow-md">
          <div class="flex flex-col items-center">
            <base-text textStyle="h2" classes="text-primary-700 mb-2.5"> Preview Your Application </base-text>
            <div v-if="doesDevStoreExist" class="flex flex-col md:flex-row md:items-center">
              <base-text textStyle="h4" classes="text-neutrals-black flex">
                You can preview the application to verify the information through the
              </base-text>
              <a :href="applicationURL" class="flex justify-center" target="_blank" rel="noreferrer noopener">
                <base-text textStyle="h4" classes="mt-1 md:mt-0 text-primary-700 flex items-center ms-1 cursor-pointer">
                  Zid App Market
                  <base-icon viewBox="0 0 20 20" iconName="enter-icon" class="ms-1">
                    <enter-icon />
                  </base-icon>
                </base-text>
              </a>
            </div>
            <div v-else class="flex flex-col items-center">
              <base-text textStyle="h4" classes="text-neutrals-black flex">
                Please install a development store so you can review and verify the information of your application
                before sending it for review.
              </base-text>
            </div>
          </div>
        </div>
      </transition>
      <div class="p-8 mb-8 bg-white-pureWhite rounded-lg shadow-md">
        <div class="flex flex-col items-center">
          <base-text textStyle="h2" classes="text-primary-700 mb-2.5">
            Application Is Ready To Go For Production
          </base-text>
          <base-text textStyle="h4" classes="text-neutrals-black flex mb-6">
            Make sure the previous steps are completed then Submit the application for review
          </base-text>
          <div class="flex flex-col lg:flex-row">
            <zid-button type="neutral" class="lg:me-4" @click="onBackClicked">
              <base-text textStyle="h3" classes="px-6 py-2 "> Back </base-text>
            </zid-button>
            <transition name="slide" mode="out-in">
              <zid-button type="primary" class="mt-3 lg:mt-0" @click="onSendForReviewClicked">
                <base-text textStyle="h3" classes="text-white-pureWhite px-6 py-2"> Send for review </base-text>
              </zid-button>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import { defineComponent, ref, onBeforeMount, unref } from '@vue/composition-api';
import { ZidButton, ZidModal, ZidBreadcrumb, ZidBreadcrumbItem } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EnterIcon from '@/assets/icons/applications/EnterIcon.vue';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { sendAppForRewview } from '../../../../../api/my-apps/steps/publish-app/publishApp';
import ExclamationIcon from '@/assets/icons/domains/partnership-details/ExclamationIcon.vue';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    EnterIcon,
    ZidButton,
    ZidModal,
    ExclamationIcon,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));

    const doesDevStoreExist = ref(false);
    const applicationURL = ref('');
    const isAppInstalled = ref(false);

    onBeforeMount(async () => {
      if (isNaN(unref(appID))) return;
      const appDetailsRes = await getApplicationData(unref(appID));
      if (appDetailsRes instanceof ErrorModel || !appDetailsRes.payload) {
        return;
      }
      isAppInstalled.value = appDetailsRes.payload.isAppInDevelopmentStore;
      applicationURL.value = appDetailsRes.payload.app_url;
      doesDevStoreExist.value = appDetailsRes.payload.development !== null;
    });

    const onBackClicked = () => {
      router.push({ name: RoutingRouteEnum.Admin_CreateApplication_PlansManagement });
    };

    const onSendForReviewClicked = async () => {
      if (!unref(appID)) return;
      const response = await sendAppForRewview(appID.value);
      if (response.status !== 'success') {
        Vue.$toast.error('Failed to send request for review');
        return;
      }
      Vue.$toast.success('Application is sent for review successfully');
      router.push({ name: RoutingRouteEnum.Admin_MyApps});
    };
    return {
      RoutingRouteEnum,
      applicationURL,
      doesDevStoreExist,
      isAppInstalled,
      onBackClicked,
      onSendForReviewClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.publish-app {
  .slide-enter-active {
    animation: slide-in 0.4s ease-in forwards;
  }
  .slide-leave-active {
    animation: slide-out 0.4s ease-out forwards;
  }
  @keyframes slide-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
