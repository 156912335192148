import BaseModel from '../../../api/models/BaseModel';
import ApplicationModel from '@/api/models/ApplicationModel';

class CreateApplicationResponseModel extends BaseModel {
  public code: string;
  public payload: ApplicationModel;

  constructor(arg: any) {
    super(arg);
    this.code = arg.code;
    this.payload = arg.payload;
  }
}

export default CreateApplicationResponseModel;
