import {
  AppCustomeLocationEnum,
  AppCustomeLocationValuesEnum,
  AppLanguageEnum,
  AppLanguageValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/app-details/js-app-details.type';

export const APP_LANGUAGES = [
  { label: AppLanguageEnum.Arabic, value: AppLanguageValuesEnum.Arabic },
  { label: AppLanguageEnum.English, value: AppLanguageValuesEnum.English },
];

export const APP_CUSTOM_LOCATION_SCRIPTS = [
  { label: AppCustomeLocationEnum.Global, value: AppCustomeLocationValuesEnum.Global },
  { label: AppCustomeLocationEnum.PurchaseEvent, value: AppCustomeLocationValuesEnum.PurchaseEvent },
  { label: AppCustomeLocationEnum.ProductDetailsEvent, value: AppCustomeLocationValuesEnum.ProductDetailsEvent },
  { label: AppCustomeLocationEnum.AddToCartEvent, value: AppCustomeLocationValuesEnum.AddToCartEvent },
  { label: AppCustomeLocationEnum.RemoveFromCartEvent, value: AppCustomeLocationValuesEnum.RemoveFromCartEvent },
];
