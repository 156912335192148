export const descriptionMarkdownConfigs = {
  toolbar: [
    'bold',
    'italic',
    'strikethrough',
    '|',
    'heading',
    '|',
    'heading-smaller',
    '|',
    'heading-bigger',
    '|',
    'quote',
    'code',
    '|',
    'unordered-list',
    'ordered-list',
    '|',
    'link',
    '|',
    'image',
    '|',
    'clean-block',
    '|',
    'guide',
  ],
};
