import httpClient from '@/api/httpClient';
import { ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/app/update/listing';

export const sendAppForRewview = (applicationID: number): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${applicationID}`, {
      listing_status: ApplicationListingStatusProperty.RequestToPublish,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
