import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AddPlanResponseModel from '@/domains/applications/models/AddPlanResponseModel';
import DeletePlanResponseModel from '@/domains/applications/models/DeletePlanResponseModel';
import { PlanInfoType } from '../../../../types/my-apps/create-appication/steps/app-plans';

const ENDPOINT_URL = '/market';

export const sendPlanToAPI = (
  newPlanInfo: Record<string, PlanInfoType>,
  applicationID: number,
): Promise<AddPlanResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/${applicationID}/plan`, newPlanInfo)
    .then((response) => new AddPlanResponseModel(response))
    .catch((error) => error);
};

export const deletePlan = (
  purchasableID: string,
  applicationID: number,
): Promise<DeletePlanResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${applicationID}/plan`, {
      data: {
        pricingPlans: {
          purchasable_id: purchasableID,
        },
      },
    })
    .then((response) => new DeletePlanResponseModel(response))
    .catch((error) => error);
};
