import {
  ApplicationLanguagesEnum,
  ApplicationLanguagesValuesEnum,
  ApplicationTypesEnum,
  ApplicationTypesValuesEnum,
} from '../../types/my-apps/create-appication/create-app.type';

export const APP_LANGUAGES = [
  { label: ApplicationLanguagesEnum.Arabic, value: ApplicationLanguagesValuesEnum.Arabic },
  { label: ApplicationLanguagesEnum.English, value: ApplicationLanguagesValuesEnum.English },
];

export const APP_TYPES = [
  { label: ApplicationTypesEnum.Normal, value: ApplicationTypesValuesEnum.Normal },
  { label: ApplicationTypesEnum.Javascript, value: ApplicationTypesValuesEnum.Javascript },
];
