export type SubscribtionType = {
  id: number;
  webhookSelection: string[];
  targetURL: string;
  headers: string;
};

export enum WebhookInputsEnum {
  TargetURL = 'targetURL',
  Headers = 'headers',
}

export type WebhookGroup = {
  group_id: string | null;
  webhooks: WebhookType[];
};

export type WebhookType = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
};

export type WebhookResponse = {
  id: number | null;
  name: string;
  target_url: string;
  headers: string;
  conditions?: Record<string, string>;
  app_id: number;
};
