import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import AddWebhookGroupResponseModel from '@/domains/applications/models/AddWebhookGroupResponseModel';
import DeleteWebhookResponseModel from '@/domains/applications/models/DeleteWebhookResponseModel';
import WebhooksResponseModel from '@/domains/pd-admin/models/my-apps/create-js-app.ts/webhooks-response-model';
import { WebhookGroup } from '@/domains/pd-admin/types/my-apps/create-appication/steps/app-webhooks';

const ENDPOINT_URL = '/market';

export const getAppWebhooks = (appID: number): Promise<WebhooksResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/show/${appID}/webhook`)
    .then((response) => new WebhooksResponseModel(response))
    .catch((error) => error);
};

export const setWebhookGroupDataToApi = (
  webhookGroupInfo: WebhookGroup,
  applicationID: number | string,
): Promise<AddWebhookGroupResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update/multiple/${applicationID}/webhook`, webhookGroupInfo)
    .then((response) => new AddWebhookGroupResponseModel(response))
    .catch((error) => error);
};

export const deleteWebhookGroup = (
  webhookGroupID: string,
  applicationID: number | string,
): Promise<DeleteWebhookResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/delete/${applicationID}/webhook/group`, {
      data: {
        webhook: {
          group_id: webhookGroupID,
        },
      },
    })
    .then((response) => new DeleteWebhookResponseModel(response))
    .catch((error) => error);
};
