import HttpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import { AppCustomeLocationValuesEnum } from '@/domains/pd-admin/types/administration/apps-management/app-details/js-app-details.type';

const ENDPOINT_URL = '/market';

export const updateJSAppHeadscripts = (
  appID: number,
  customLocation: AppCustomeLocationValuesEnum,
  headscriptCode: string,
): Promise<BaseModel | ErrorModel> => {
  const headscriptInfo = {
    location: customLocation === AppCustomeLocationValuesEnum.Global ? null : customLocation,
    head_scripts_code: headscriptCode,
  };

  return HttpClient.post(`${ENDPOINT_URL}/admin/update/${appID}/headscript`, headscriptInfo)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
