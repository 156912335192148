import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CategoriesResponseModel from '@/domains/applications/models/CategoriesResponseModel';

const ENDPOINT_URL = '/market/partner-categories';

export const getPartnerCategories = (): Promise<CategoriesResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new CategoriesResponseModel(response))
    .catch((error) => error);
};
