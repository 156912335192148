import Vue from 'vue';
import { defineComponent, ref, unref } from '@vue/composition-api';
import {
  ZidInput,
  ZidButton,
  ZidTextarea,
  ZidFileSelector,
  ZidLoader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
} from '@zidsa/ui';
import VueEasymde from 'vue-easymde';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import BaseText from '@/components/text/BaseText.vue';
import { RoutingRouteEnum } from '@/router/routes.enum';
import router from '@/router/index';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import ErrorModel from '@/api/models/ErrorModel.ts';
import { setApplicationDetailsToApi } from '@/domains/pd-admin/api/my-apps/steps/application-details/applicationDetails';
import useCreateAppDetails from '@/domains/pd-admin/helpers/my-apps/create-app-details/useCreateAppDetails';
import { descriptionMarkdownConfigs } from '@/domains/pd-admin/helpers/administration/apps-management/app-details/app-details-common';
import InstallAppOnDevelopmentStore from '@/domains/development-store/components/install-app-on-development-store/InstallAppOnDevelopmentStore.vue';

const { useActions, useGetters } = createNamespacedHelpers('application');

export default defineComponent({
  components: {
    ZidInput,
    ZidButton,
    BaseText,
    VueEasymde,
    ZidTextarea,
    TabMultiLanguage,
    ZidFileSelector,
    ZidLoader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    InstallAppOnDevelopmentStore,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));
    const { stepsCompleted } = useGetters(['stepsCompleted']);
    const { addStepCompleted } = useActions(['addStepCompleted']);

    let {
      createAppDetailsState,
      v$,
      applicationDetails,
      mdAppLink,
      iconFileNameFromGlobal,
      isIconSpecsValid,
      isSaveDisabled,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      originalArabicScreenshots,
      originalEnglishScreenshots,
      areArabicFiles,
      onIconSelection,
      onInputDataChange,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
    } = useCreateAppDetails(unref(appID));

    const onBackClicked = () => {
      router.push({
        name: RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript,
      });
    };

    const onSubmit = async () => {
      const appDetailsData = {
        name: applicationDetails.app.name,
        name_ar: applicationDetails.app.name_ar,
        app_category_id: applicationDetails.app.category.id,
        is_html_enabled: applicationDetails.app.is_html_enabled,
        appURL: createAppDetailsState.appURL,
        description: createAppDetailsState.description,
        descriptionArabic: createAppDetailsState.descriptionArabic,
        shortDesciption: createAppDetailsState.shortDesciption,
        shortDesciptionArabic: createAppDetailsState.shortDesciptionArabic,
        developerName: createAppDetailsState.developerName,
        developerNameArabic: createAppDetailsState.developerNameArabic,
        videoURL: createAppDetailsState.videoURL,
        appScreenshotsAr: [...originalArabicScreenshots.list],
        appScreenshotsEn: [...originalEnglishScreenshots.list],
        iconFile: createAppDetailsState.iconFile instanceof File ? createAppDetailsState.iconFile : null,
        storeLine: createAppDetailsState.storeLine,
        loginLink: createAppDetailsState.loginLink,
      };

      const response = await setApplicationDetailsToApi(appDetailsData, unref(appID));
      if (response instanceof ErrorModel) {
        Vue.$toast.error('Failed to update application details');
        return;
      }
      addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_WebhookManagement);
      addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_PlansManagement);
      addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_Publish);
      Vue.$toast.success('Updated application details successfully!');
      router.push({ name: RoutingRouteEnum.Admin_CreateApplication_WebhookManagement });
    };

    const onRemoveIconClicked = () => {
      createAppDetailsState.iconFile = null;
      iconFileNameFromGlobal.value = '';
    };

    return {
      descriptionMarkdownConfigs,
      RoutingRouteEnum,
      createAppDetailsState,
      v$,
      isSaveDisabled,
      iconFileNameFromGlobal,
      isIconSpecsValid,
      showMessageForDescriptionArEnField,
      showMessageDeveloperNameField,
      isArScreenshotsInvalid,
      isEnScreenshotsInvalid,
      initialArabicScreenshots,
      initialEnglishScreenshots,
      isInitialArabicScreenshotsLoading,
      isInitialEnglishScreenshotsLoading,
      areArabicFiles,
      stepsCompleted,
      onRemoveIconClicked,
      onIconSelection,
      onInputDataChange,
      onSubmit,
      onBackClicked,
      onArabicScreenshotsChange,
      onEnglishScreenshotsChange,
      onArabicScreenshotsRemove,
      onEnglishScreenshotsRemove,
      appID,
      mdAppLink,
    };
  },
});
