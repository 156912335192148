import { defineComponent, ref, unref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidIcon,
  ZidRadio,
  ZidButton,
  ZidModal,
  ZidInput,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTextarea,
  ZidLoader,
  ZidSwitch,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage';
import { RoutingRouteEnum } from '@/router/routes.enum';
import usePlanManagement from '../../../../../helpers/my-apps/plans-management/usePLanManagement';
import { PlanTypes } from '../../../../../types/my-apps/create-appication/steps/app-plans';

export default defineComponent({
  components: {
    ZidIcon,
    BaseText,
    ZidRadio,
    ZidButton,
    BaseIcon,
    DeleteIcon,
    EditIcon,
    ZidModal,
    ZidInput,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidTextarea,
    ZidLoader,
    ZidSwitch,
    TabMultiLanguage,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));

    return {
      RoutingRouteEnum,
      PlanTypes,
      ...usePlanManagement(unref(appID)),
    };
  },
});
