import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import CreateApplicationInformationModel from '@/domains/applications/models/CreateApplicationInformationModel';
import CreateApplicationResponseModel from '@/domains/applications/models/CreateApplicationResponseModel';

const ENDPOINT_URL = '/market/app/create';

export const createApplication = (
  applicationInfo: CreateApplicationInformationModel,
): Promise<CreateApplicationResponseModel | ErrorModel> => {
  return httpClient
    .post(ENDPOINT_URL, applicationInfo)
    .then((response) => new CreateApplicationResponseModel(response))
    .catch((error) => error);
};
