import Vue from 'vue';
import { defineComponent, ref, unref } from '@vue/composition-api';
import {
  ZidInput,
  ZidTextarea,
  ZidButton,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
} from '@zidsa/ui';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import router from '@/router/index';
import ErrorModel from '@/api/models/ErrorModel';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import useCreateAppGeneralSettinsJavascript from '@/domains/pd-admin/helpers/my-apps/create-app-general-settings/useCreateAppGeneralSettingsJavascript';
import { updateJSAppHeadscripts } from '@/domains/pd-admin//api/my-apps/steps/general-settings/generalSettings';

const { useGetters, useActions } = createNamespacedHelpers('application');

export default defineComponent({
  components: {
    ZidInput,
    BaseText,
    ZidTextarea,
    ZidButton,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
  },
  setup(_, ctx) {
    const appID = ref(Number(ctx.root.$route.params.id));
    const { stepsCompleted } = useGetters(['stepsCompleted']);
    const { addStepCompleted } = useActions(['addStepCompleted']);

    let {
      createAppGeneralSettingsState,
      v$,
      JavascriptAppGeneralSettingsInputPropertiesEnum,
      JavascriptAppGeneralSettingsSelectPropertiesEnum,
      EmptySelectEnum,
      appCustomLocationScripts,
      isSubmitScriptsDisabled,
      headScripts,
      onSelectionDataChange,
      onInputDataChange,
    } = useCreateAppGeneralSettinsJavascript(unref(appID));

    addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript);
    addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_AppDetails);

    const onSubmitScriptsInformation = async () => {
      const response = await updateJSAppHeadscripts(
        appID.value,
        createAppGeneralSettingsState.customLocation.value,
        createAppGeneralSettingsState.updateCode,
      );
      if (response instanceof ErrorModel || response.status !== 'success') {
        Vue.$toast.error('Could not update application headscripts information');
        return;
      }
      Vue.$toast.success('Application headscripts information have been updated');

      const prevIndex = headScripts.list.findIndex((script) => {
        if (script.location === null) {
          return createAppGeneralSettingsState.customLocation.value === '';
        }
        return script.location === createAppGeneralSettingsState.customLocation.value;
      });

      if (prevIndex !== -1) {
        headScripts.list.splice(prevIndex, 1, {
          head_scripts_code: createAppGeneralSettingsState.updateCode,
          location:
            createAppGeneralSettingsState.customLocation.value === ''
              ? null
              : createAppGeneralSettingsState.customLocation.value,
        });
      } else {
        headScripts.list.push({
          head_scripts_code: createAppGeneralSettingsState.updateCode,
          location:
            createAppGeneralSettingsState.customLocation.value === ''
              ? null
              : createAppGeneralSettingsState.customLocation.value,
        });
      }
    };

    const onContinueClicked = () => {
      router.push({ name: RoutingRouteEnum.Admin_CreateApplication_AppDetails });
    };

    return {
      RoutingRouteEnum,
      createAppGeneralSettingsState,
      v$,
      JavascriptAppGeneralSettingsInputPropertiesEnum,
      JavascriptAppGeneralSettingsSelectPropertiesEnum,
      EmptySelectEnum,
      appCustomLocationScripts,
      isSubmitScriptsDisabled,
      headScripts,
      stepsCompleted,
      onSelectionDataChange,
      onInputDataChange,
      onSubmitScriptsInformation,
      onContinueClicked,
    };
  },
});
