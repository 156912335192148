import Vue from 'vue';
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import BaseText from '@/components/text/BaseText.vue';
import { ZidButton, ZidIcon, ZidInput, ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption } from '@zidsa/ui';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import PartnerCategoryModel from '@/domains/applications/models/PartnerCategoryModel';
import CreateApplicationInformationModel from '@/domains/applications/models/CreateApplicationInformationModel';
import { createApplication } from '../../../api/my-apps/createApplication';
import { getPartnerCategories } from '../../../api/my-apps/partner-categories';
import useCreateJSApplication from '../../../helpers/my-apps/useCreateJSApplication';
import ErrorModel from '@/api/models/ErrorModel';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';

const { useActions } = createNamespacedHelpers('application'); // module name

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    ZidIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    TabMultiLanguage,
  },
  setup() {
    const isLoadingSubmit = ref(false);

    const {
      createAppFormState,
      appCategories,
      appLanguages,
      v$,
      showMessageForArabicEnglishField,
      onInputDataChange,
      onSelectionDataChange,
      onCancelClicked,
    } = useCreateJSApplication();

    const { addStepCompleted } = useActions(['addStepCompleted']);

    onBeforeMount(async () => {
      const response = await getPartnerCategories();
      if (response instanceof ErrorModel || !response.categories) {
        Vue.$toast.error('Failed to load categories');
        router.back();
        return;
      }
      appCategories.options = [];
      const { categories } = response;
      if (categories.length > 0) {
        categories.forEach((category: PartnerCategoryModel) => {
          appCategories.options.push({ label: category.name_en, value: category.id });
        });
      } else Vue.$toast.error('Categories is empty');
    });

    const onSubmit = async () => {
      isLoadingSubmit.value = true;
      const createApplicationInformation = new CreateApplicationInformationModel({
        name: v$.value.appNameEnglish.$model,
        name_ar: v$.value.appNameArabic.$model,
        url: v$.value.appURL.$model,
        app_category_id: v$.value.appCategory.value.$model,
        language: v$.value.appLanguage.value.$model,
        email: v$.value.maintainerEmail.$model,
        type: `${ApplicationTypeProperty.Javascript}`,
      });
      const response = await createApplication(createApplicationInformation);
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to create application');
        isLoadingSubmit.value = false;
        return;
      }

      isLoadingSubmit.value = false;
      addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript);
      Vue.$toast.success('Created application successfully');

      router
        .push({
          name: RoutingRouteEnum.Admin_CreateApplication_GeneralSettings_Javascript,
          params: {
            id: `${response.payload.id}`,
          },
        })
        .catch((err) => {
          //
        });
    };

    return {
      createAppFormState,
      appCategories,
      appLanguages,
      isLoadingSubmit,
      v$,
      showMessageForArabicEnglishField,
      onInputDataChange,
      onSelectionDataChange,
      onCancelClicked,
      onSubmit,
    };
  },
});
