import Vue from 'vue';
import { reactive, ref, watch, onBeforeUnmount, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import router from '@/router/index';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { helpers, required } from '@vuelidate/validators';
import { RoutingRouteEnum } from '@/router/routes.enum';
import {
  SubscribtionType,
  WebhookGroup,
  WebhookInputsEnum,
  WebhookResponse,
  WebhookType,
} from '@/domains/pd-admin/types/my-apps/create-appication/steps/app-webhooks';
import {
  setWebhookGroupDataToApi,
  deleteWebhookGroup,
  getAppWebhooks,
} from '../../../api/my-apps/steps/webhook-management/webhookManagement';
import { URL_REGEX } from '@/helpers/regex/URLs';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('application');

const useWebhooksManagement = (appID: number) => {
  const { addStepCompleted } = useActions(['addStepCompleted']);

  const showAddWebhook = ref(false);
  const loadingAddOrEditWebhook = ref(false);
  const showDeleteWebhookModal = ref(false);
  const isDeletingWebhookGroup = ref(false);
  const selectedWebhookGroupID = ref('');
  const webhooksOptions: Record<string, string[]> = reactive({ options: [] });
  let existingWebhookGroups: Record<string, WebhookResponse[]> | null = null;

  const createAppWebhooksState: SubscribtionType = reactive({
    id: -1,
    webhookSelection: [],
    targetURL: '',
    headers: '',
  });

  const webhookSubscribtionList: Record<string, WebhookGroup[]> = reactive({ subscirbtionList: [] });

  const isValidJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const rules = {
    webhookSelection: {
      required,
      webhookSelection: helpers.withMessage('Please select application webhooks', required),
    },
    targetURL: {
      required,
      targetURL: helpers.withMessage('URL is not valid', (value: string) => URL_REGEX.test(value)),
    },
    headers: {
      headers: helpers.withMessage(
        'JSON format is not valid',
        (value: string) => isValidJsonString(value) || value === '',
      ),
    },
  };

  const v$ = useVuelidate(rules, createAppWebhooksState);

  const toggleAddwebhook = () => (showAddWebhook.value = !showAddWebhook.value);
  const toggleDeleteWebhookModal = () => (showDeleteWebhookModal.value = !showDeleteWebhookModal.value);

  onBeforeMount(async () => {
    await fetchWebhooks();
    updateCurrentSupscriptionList();
  });

  const fetchWebhooks = async () => {
    const webhooksRes = await getAppWebhooks(appID);
    if (webhooksRes instanceof ErrorModel) {
      return;
    }
    existingWebhookGroups = { ...webhooksRes.webhooks };
    webhooksOptions.options = [...webhooksRes.webhooksAvailable];
  };

  const updateCurrentSupscriptionList = () => {
    if (!existingWebhookGroups) return;
    webhookSubscribtionList.subscirbtionList = [];
    for (const [key, value] of Object.entries(existingWebhookGroups)) {
      webhookSubscribtionList.subscirbtionList.push({
        group_id: key,
        webhooks: value,
      });
    }
  };

  const onWebhookGroupDelete = (webhookGroupID: string) => {
    selectedWebhookGroupID.value = webhookGroupID;
    showDeleteWebhookModal.value = true;
  };

  const onDeleteConfirmationClicked = async () => {
    if (!appID || !selectedWebhookGroupID) {
      return;
    }
    isDeletingWebhookGroup.value = true;
    const response = await deleteWebhookGroup(selectedWebhookGroupID.value, appID);
    if (response instanceof ErrorModel) {
      isDeletingWebhookGroup.value = false;
      Vue.$toast.error('Failed to delete webhook group');
      toggleDeleteWebhookModal();
      return;
    }
    await fetchWebhooks();
    updateCurrentSupscriptionList();
    isDeletingWebhookGroup.value = false;
    selectedWebhookGroupID.value = '';
    Vue.$toast.success('Webhook group has been deleted');
    toggleDeleteWebhookModal();
  };

  const onSubscribeWebhookClicked = () => {
    if (!showAddWebhook.value) toggleAddwebhook();
  };

  const prepareWebhookGroupData = () => {
    const webhooks: WebhookType[] = [];
    let webhookGroupID = null;
    let selectedWebhookGroup: WebhookGroup | null = null;
    if (selectedWebhookGroupID.value) {
      webhookGroupID = selectedWebhookGroupID.value;
      selectedWebhookGroup =
        webhookSubscribtionList.subscirbtionList.find(
          (webhhook) => webhhook.group_id === selectedWebhookGroupID.value,
        ) || null;
    }
    createAppWebhooksState.webhookSelection.map((selection) => {
      webhooks.push({
        id: selectedWebhookGroup?.webhooks.find((webhook) => webhook.name === selection)?.id || null,
        name: selection,
        target_url: createAppWebhooksState.targetURL,
        headers: createAppWebhooksState.headers ? JSON.parse(createAppWebhooksState.headers) : null,
      });
    });

    const newWebhookGroup: WebhookGroup = {
      group_id: webhookGroupID,
      webhooks,
    };
    return newWebhookGroup;
  };

  const onSaveOrEditClicked = async () => {
    if (appID) {
      loadingAddOrEditWebhook.value = true;
      const newWebhookGroup = prepareWebhookGroupData();

      const response = await setWebhookGroupDataToApi(newWebhookGroup, appID);
      if (response instanceof ErrorModel || !response.webhooks) {
        loadingAddOrEditWebhook.value = false;
        if (selectedWebhookGroupID.value !== '') Vue.$toast.error('Failed to update webhook group');
        else Vue.$toast.error('Failed to Add new webhook group');
        return;
      }
      await fetchWebhooks();
      updateCurrentSupscriptionList();

      if (selectedWebhookGroupID.value !== '') Vue.$toast.success('Updated webhook group successfully');
      else Vue.$toast.success('Added new webhook group successfully');
    }
    loadingAddOrEditWebhook.value = false;
    selectedWebhookGroupID.value = '';
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    v$.value.$reset();
    toggleAddwebhook();
  };

  const onContinueClicked = () => {
    addStepCompleted(RoutingRouteEnum.Admin_CreateApplication_PlansManagement);
    router.push({ name: RoutingRouteEnum.Admin_CreateApplication_PlansManagement });
  };

  const onCancelClicked = () => {
    createAppWebhooksState.webhookSelection = [];
    createAppWebhooksState.targetURL = '';
    createAppWebhooksState.headers = '';
    v$.value.$reset();
    toggleAddwebhook();
  };

  const onWebhookGroupEdit = (webhookGroupID: string) => {
    if (!showAddWebhook.value) toggleAddwebhook();
    if (webhookGroupID !== selectedWebhookGroupID.value) {
      selectedWebhookGroupID.value = webhookGroupID;
      createAppWebhooksState.webhookSelection = [];
      createAppWebhooksState.targetURL = '';
      createAppWebhooksState.headers = '';
      v$.value.$reset();
    }
    const webhooks = webhookSubscribtionList.subscirbtionList.find(
      (webhookGroup: WebhookGroup) => webhookGroup.group_id === webhookGroupID,
    )?.webhooks;
    if (webhooks?.length) {
      createAppWebhooksState.webhookSelection = webhooks?.map((webhook) => webhook.name);
      createAppWebhooksState.targetURL = webhooks[0].target_url;
      createAppWebhooksState.headers = webhooks[0].headers ?? '{}';
    }
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: WebhookInputsEnum) => {
    v$.value[dataProperty].$touch();
    createAppWebhooksState[dataProperty] = changedValue.value;
  };

  const onWebhookSelectionRemoved = (removedWebhook: string) => {
    if (webhooksOptions.options.findIndex((wh) => wh === removedWebhook) === -1) {
      const newAllowedWebhooksAfterRemoving = [...webhooksOptions.options, removedWebhook];
      webhooksOptions.options = [...newAllowedWebhooksAfterRemoving];
    }
  };

  onBeforeUnmount(() => {
    if (selectedWebhookGroupID.value !== '' || !createAppWebhooksState.webhookSelection.length) return;
    const newAllowedWebhooksAfterRemoving = [...webhooksOptions.options, ...createAppWebhooksState.webhookSelection];
    webhooksOptions.options = [...newAllowedWebhooksAfterRemoving];
  });

  return {
    createAppWebhooksState,
    webhooksOptions,
    webhookSubscribtionList,
    showAddWebhook,
    loadingAddOrEditWebhook,
    showDeleteWebhookModal,
    isDeletingWebhookGroup,
    selectedWebhookGroupID,
    WebhookInputsEnum,
    v$,
    onInputDataChange,
    onWebhookGroupDelete,
    onSubscribeWebhookClicked,
    onSaveOrEditClicked,
    onContinueClicked,
    onCancelClicked,
    onWebhookGroupEdit,
    toggleDeleteWebhookModal,
    onDeleteConfirmationClicked,
    onWebhookSelectionRemoved,
  };
};

export default useWebhooksManagement;
